import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../../components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import TimeOffNavbar from './TimeOffNavbar';
import TimeOffList from './TimeOffList';
import TimeOffPagination from './TimeOffPagination';
import { HiOutlineCalendar } from "react-icons/hi";
import { formatDateTimeOff } from "../../common/utils";
import ItemsSelect from '../ItemsSelect';

export type TimeOffInput = {
    employee: string | null,
    date: string | null,
    queryTerm?: string | null,
    currentPage?: number,
	sortValue?: string | null,
	orderValue?: string | null,
    idWorkflowState: number | null,
    idTimeOffRequestType: number | null
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: any
};

const TimeOff = ({ HRView = false }) => {

    const [input, setInput] = useState<TimeOffInput | null>({
        employee: "",
        date: "",
        queryTerm: "",
        currentPage: 1,
		sortValue: "employee",
		orderValue: "asc",
        idWorkflowState: null,
        idTimeOffRequestType: null
	});

    const [requestsHR, setRequestsHR] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});

    const [myRequests, setMyRequests] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});

    const [requestType, setRequestType] = useState(null);

    const [requestStatus, setRequestStatus] = useState(null);
    
    const api: Api = useApi();

    const [pageCount, setpageCount] = useState<number>(0);

    const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageTimeOff'), 10) || 10;
	});

    useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageTimeOff'), 10) || 10);
	}, []);

    const toast = useToastMessageQueue();

    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const fetchRequestsHR = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        if (input.idWorkflowState) {
            f = [`state:${input.idWorkflowState}`];
        }
        if (input.idTimeOffRequestType) {
            f = f || [];
            f.push(`type:${input.idTimeOffRequestType}`);
        }
        try {
            const response = await api.timeOffApi.apiVversionTimeOffSearchGet("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {});
            if (response.status === 200) {
                if (response.data?.data?.queryResult) {
                    const formattedDates = response.data?.data?.queryResult?.map(req => {
                        return {
                            ...req,
                            from: formatDateTimeOff(req.from),
                            to: formatDateTimeOff(req.to)
                        };
                    });
                    setRequestsHR({
                        totalRecords: response.data.data.totalRecords,
                        queryResult: formattedDates
                    });
                } else {
                    setRequestsHR({
                        totalRecords: null,
                        queryResult: null
                    });
                }
            } else {
                toast.error({ header: intl.get('timeOff.toast.error.fetchRequests'), body: response.data.errors });
            };
        } catch (error) {
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequests'), body: error.message });
        } finally {
            setLoading(false);
        }
    };

    const fetchRequestsSimpleUser = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        try {
            const response = await api.timeOffApi.apiVversionTimeOffSearchMineGet("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {});
            if (response.status === 200) {
                if (response.data?.data?.queryResult) {
                    const formattedDates = response.data?.data?.queryResult?.map(req => {
                        return {
                            ...req,
                            from: formatDateTimeOff(req.from),
                            to: formatDateTimeOff(req.to)
                        };
                    });
                    setMyRequests({
                        totalRecords: response.data.data.totalRecords,
                        queryResult: formattedDates
                    });
                } else {
                    setMyRequests({
                        totalRecords: null,
                        queryResult: null
                    });
                }
            } else {
                toast.error({ header: intl.get('timeOff.toast.error.fetchRequests'), body: response.data.errors });
            };
        } catch (error) {
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequests'), body: error.message });
        } finally {
            setLoading(false);
        }
    };

    const fetchRequestStatus = async () => {
        const response = await api.workflowApi.apiVversionWorkflowStatesByModuleNameModuleNameGet("1","TimeOff",  {}).then((response) => {
            if (response.data.data) {
                setRequestStatus({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };
            console.log("Success");
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequestStatus'), body: error.message });
        });
    };

    const fetchRequestType = async () => {
        const response = await api.timeOffRequestTypeApi.apiVversionTimeOffRequestTypeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setRequestType({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            } else {
                setRequestType({
                    totalRecords: null,
                    queryResult: null
                });
            };
            console.log("Success");
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequestType'), body: error.message });
        });
    };

    useEffect(() => {
        if (HRView) {
            fetchRequestsHR();
        } else {
            fetchRequestsSimpleUser()
        }
        fetchRequestStatus();
        fetchRequestType();
	}, [input, limit, HRView]);

    const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    const onSearch = (queryString, idStatus, idRequest) => {
		setInput({ ...input, queryTerm: queryString, idWorkflowState: idStatus, idTimeOffRequestType: idRequest, currentPage: 1 });
	};

    const handleCalendarView = (HRView: boolean) => {
        if (HRView) {
            navigate('/CalendarHR');
        } else {
            navigate('/Calendar');
        }
    };

    return (
        <div className="container">
            <div className='card mt-4'>
                <div className="card-header">
                    {
                        HRView ?
                        <h2 className="title">{intl.get('timeOff.header.HR')}</h2>
                        :
                        <h2 className="title">{intl.get('timeOff.header')}</h2>
                    }
                    <a type='button' className="link-secondary newEmployeeSize" onClick={() => handleCalendarView(HRView)} >{intl.get('timeOff.calendar')}<HiOutlineCalendar className='ms-1 mb-1' /></a>
                </div>
                <div className='card-body'>
                    <div>
                        <TimeOffNavbar 
                            requestStatus={requestStatus?.queryResult}
                            requestType={requestType?.queryResult}
                            onSearch={onSearch}
                            HRView={HRView}
                        />
                        {
                            loading === true ?
                            <Spinner /> :
                            <>
                                <TimeOffList searchResult={HRView ? requestsHR : myRequests} HRView={HRView} fetchRequestsHR={fetchRequestsHR} fetchRequestsSimpleUser={fetchRequestsSimpleUser} />
                                <div className="pagination row w-100">
                                    <div className="col-10 col-xl-11 d-flex justify-content-center">
                                        <TimeOffPagination HRView={HRView} handlePageClick={handlePageClick} limit={limit} searchResult={HRView ? requestsHR : myRequests} nextPage={nextPage} prevPage={prevPage} input={input} pageCount={pageCount} setpageCount={setpageCount} initialPage={initialPage} finalPage={finalPage} />
                                    </div>
                                    <div className="col-2 col-xl-1 d-flex justify-content-end">
                                        <ItemsSelect 
                                            input={input}
                                            setInput={setInput}
                                            limit={limit} 
                                            setLimit={setLimit} 
                                            storageKey="itemsPerPageTimeOff"
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeOff;