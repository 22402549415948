import React, { useEffect, useState } from 'react';
import { Form, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { DiJira } from "react-icons/di";
import { HiOutlineSave, HiOutlineTrash, HiOutlineXCircle } from 'react-icons/hi';
import { formatDate, formatDateForAPI, formatTime, unformatTime } from 'common/utils';
import InputMask from 'react-input-mask';
import intl from 'react-intl-universal';
import JiraIssueSearch from 'components/Project/JiraIssueSearch';
import { GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse, JiraIssue, Project, Task } from 'axiosApi/models';
import { confirmAlert } from 'react-confirm-alert';
import Api from 'axiosApi/api';
import { useApi } from '../../api/ApiProvider';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import Spinner from '../Spinner';
import '../../scss/Timesheet/TimesheetForm.scss';
import { getTaskName, TimesheetInput } from './timesheetUtils';
type TimesheetFormProps = {
    id: number;
    projects: Project[];
    task: Task;
    errors: any;
    input: TimesheetInput;
    handleInputChange: (event: any) => void;
    closeModal: () => void;
    handleTimesheetSaveLog: () => void;
    importDescriptionFromJira: () => void;
    loading: boolean;
    timesheetRows: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } | undefined,
    setTimesheetRows: Function
};

const TimesheetForm = ({ id, projects, task, errors, input, handleInputChange, closeModal, handleTimesheetSaveLog, importDescriptionFromJira, loading, timesheetRows, setTimesheetRows }: TimesheetFormProps) => {

    const [jiraSearch, setJiraSearch] = useState(false);

    const [jiraEnabled, setJiraEnabled] = useState(false);

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    useEffect(() => {
        if (projects && projects.length > 0) {
            var project = projects.find((x) => x.id == input.idProject);
            setJiraEnabled((project.jiraUrl != null && project.jiraProjectKey != null && project.jiraUser != null && project.jiraToken != null));

        }
    }, []);

    const handleIssuesAdd = (issues: JiraIssue[]) => {
        var description = '';
        issues.forEach(issue => {
            description += `${issue.key} ${issue.summary}\n`
        })
        handleInputChange({ 'target': { name: 'description', value: `${input.description}${input.description.length > 0 ? '\n' : ''}${description}` } });
    }
    const beforeMaskedValueChange = (newState, oldState, userInput) => {

        var { value } = newState;

        var selection = (value.indexOf('_') > -1) ? { start: value.indexOf("_"), end: value.indexOf("_") } : newState.selection;
        var cursorPosition = selection ? selection.start : 5;
        // keep minus if entered by user
        if (cursorPosition === value.length && userInput != null && value.replaceAll(':', '').replaceAll('_', '').length < 4) {
            value = value.slice(1, 5) + userInput;
            value = value.replaceAll(':', '');
            value = value.slice(0, 2) + ":" + value.slice(2, 4);
            selection = { start: cursorPosition, end: cursorPosition };
        }
        return {
            value,
            selection
        };
    }

    const handleFocus = (value, target) => {
        target.selectionStart = 5;
        target.selectionEnd = 5;
        if (value != "0_:00")
            return unformatTime(value);
        else return;
    }

    const handleDeleteDay = (id) => {

        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('timesheetComponent.delete.record'),
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => { }
                },
                {
                    label: 'Accept',
                    onClick: () => {
                        api.timesheetApi.apiVversionTimesheetIdDelete(id, "1", {}).then((response) => {
                            if (response.status == 200) {
                                toast.success({ body: intl.get('timesheetForm.toast.success.handleDeleteDay') });
                                const date = formatDateForAPI(input.date);
                                const newTimesheetRows = timesheetRows
                                const newTimesheetsGroupedByDate = timesheetRows[input.task.id].timesheetsGroupedByDate;
                                const keys = Object.keys(timesheetRows[input.task?.id].timesheetsGroupedByDate);
                                const timesheetRowsCleaned = {};
                                keys.forEach(key => {
                                    if (key !== date) {
                                        timesheetRowsCleaned[key] = newTimesheetsGroupedByDate[key]
                                    }
                                })
                                newTimesheetRows[input.task?.id].timesheetsGroupedByDate = timesheetRowsCleaned
                                setTimesheetRows(newTimesheetRows);
                                closeModal();
                            }
                            else {
                                toast.error({ header: intl.get('employeeProject.toast.error.handleEmployeeDelete') });
                            }
                        }).catch((error) => {
                            toast.error({ header: intl.get('timesheetForm.toast.error.handleDeleteDay'), body: error.message });
                        });
                    },
                }
            ]
        });
    };

    const getClientProjectName = (projects, idProject) => {
        if (projects && projects.length > 0) {
            var project = projects.find((x) => x.id == idProject);
            return project.client.name + ' - ' + project.name;
        }
        return '';
    }



    return (
        input &&
        <Form className='timesheet-form' onSubmit={(e) => { e.preventDefault(); handleTimesheetSaveLog() }}>
            <Row>
                <Col md={7}>
                    <FormGroup>
                        <Col md={12}>
                            <Label for="Project">{intl.get('timesheetForm.clientproject')} {errors.idProject && (<p className='timesheet-danger'>{errors.idProject}</p>)}</Label>
                            <span
                                className='d-block text-muted'>{getClientProjectName(projects, input.idProject)}</span>

                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={12}>
                            <Label for="Task">{intl.get('timesheetForm.task')} {errors.idTask && (<p className='timesheet-danger'>{errors.idTask}</p>)}</Label>
                            <span
                                className='d-block text-muted'>{getTaskName(task)}</span>
                        </Col>
                    </FormGroup>
                </Col>
                <Col md={5} >
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="date">{intl.get('timesheetForm.date')} {errors.date && (<p className='timesheet-danger'>{errors.date}</p>)}</Label>
                                <span
                                    className='d-block text-muted'>{formatDate(input.date)}</span>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <Label className='d-block' for="duration">{intl.get('timesheetForm.duration')} {errors.duration && (<span className='timesheet-danger'>{errors.duration}</span>)}</Label>
                                <InputMask
                                    mask="99:99"
                                    autoFocus={true}
                                    className={'form-control timesheet-timepicker input-width' + (errors.duration ? ' timesheet-danger' : '')}
                                    id="duration"
                                    name="duration"
                                    value={input.duration ? input.duration : '0_:00'}
                                    onChange={handleInputChange}
                                    required
                                    type="text"
                                    onBlur={(e) => e.target.value = formatTime(e.target)}
                                    onFocus={(e) => handleFocus(e.target.value, e.target)}
                                    beforeMaskedValueChange={(beforeMaskedValueChange)}
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Col md={12}>
                            <Label for="Description">{intl.get('timesheetForm.description')}</Label> {jiraEnabled && <DiJira className='mb-1' title='Import description from Jira' role="button" onClick={() => { setJiraSearch(!jiraSearch); }}></DiJira>}
                            {jiraEnabled && jiraSearch && <JiraIssueSearch idProject={input.idProject} handleIssuesAdd={handleIssuesAdd} tasks={[]} />}
                            <Input
                                id="Description"
                                name="description"
                                placeholder={intl.get('timesheetForm.placeholder.description')}
                                type="textarea"
                                rows="5"
                                value={input.description ? input.description : ''}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {
                            loading === true ?
                                <Spinner small={true} /> :
                                <>
                                    <Button outline={true} type="button" className="btn btn-outline-dark" onClick={() => { closeModal() }}><HiOutlineXCircle className='mb-1' /> {intl.get('cancel.button')}</Button>
                                    {id != -1 && <Button outline={true} type="button" className="btn btn-outline-danger" onClick={() => { handleDeleteDay(id) }}><HiOutlineTrash className='mb-1' /> {intl.get('delete.modal.delete.button')}</Button>}
                                    <Button outline={true} type="submit" className="btn btn-primary btn-save" onClick={(e) => { e.preventDefault(); handleTimesheetSaveLog(); }}><HiOutlineSave className='mb-1' /> {intl.get('save.button')}</Button>
                                </>
                        }
                    </div>
                </Col>
            </Row>
        </Form>

    )
}

export default TimesheetForm;