// WorkflowContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import axios from 'axios';
import { WorkflowDetails } from '../../axiosApi/models/workflow-details';
import Api from '../../axiosApi/api';
import { useApi } from '../../api/ApiProvider';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';

interface WorkflowContextProps {
  workflow: WorkflowDetails | null;
  loading: boolean;
  error: Error | null;
}



const WorkflowContext = createContext<WorkflowContextProps | undefined>(undefined);

interface WorkflowProviderProps {
  workflowId: number;
  children: ReactNode;
}

export const WorkflowProvider: React.FC<WorkflowProviderProps> = ({ workflowId, children }) => {
  const [workflow, setWorkflow] = useState<WorkflowDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const api: Api = useApi();
  const toast = useToastMessageQueue();
  
  useEffect(() => {
    const fetchWorkflow = async () => {
        try {
            const response = await api.workflowApi.apiVversionWorkflowIdScopedGet("1", workflowId ,{});
            if (response.status === 200) {
              if (response.data?.data) {
                setWorkflow(response.data.data);
              } else {
                setWorkflow(null);
              }
            } else {
              toast.error({ header: intl.get('workflowActionsBar.toast.error.fetchTransitions'), body: response.data.errors });
            };
      
          } catch (error) {
            toast.error({ header: intl.get('workflowActionsBar.toast.error.fetchTransitions'), body: error.message });
          }
        };
      

    if (workflowId) {
      fetchWorkflow();
    }
  }, [workflowId]);

  return (
    <WorkflowContext.Provider value={{ workflow, loading, error }}>
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflow = (): WorkflowContextProps => {
  const context = useContext(WorkflowContext);
  if (context === undefined) {
    throw new Error('useWorkflow must be used within a WorkflowProvider');
  }
  return context;
};
