import React, { useState, useEffect, FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EmployeeForm from './EmployeeForm';
import AddressCompact from '../AddressCompact';
import ContactInfoCompact from '../ContactInfoCompact';
import '../../scss/EmployeeDetail.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { CreateEmployeeCommand, UpdateEmployeeCommand } from '../../axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { SCOPES } from 'common/permissions';
import { ButtonWithOptions, ButtonWithOptionsType } from "components/common/ButtonWithOptions";
import { HiArrowLeft, HiOutlineSave, HiOutlinePencilAlt, HiOutlineEye, HiPencilAlt } from 'react-icons/hi';
import { PermissionsGate } from 'components/PermissionsGate';
import RequiredField from '../RequiredField';
import intl from 'react-intl-universal';
import Spinner from '../Spinner';
import CurrentAssignations from './CurrentAssignations';

export type EmployeeDetailInput = {
    name?: string | null,
    lastName?: string | null,
    emailAddress?: string | null,
};

const EmployeeDetail = () => {

    const [input, setInput] = useState<EmployeeDetailInput | null>({
        name: "",
        lastName: "",
        emailAddress: "",
    });

    const api: Api = useApi();

    const navigate = useNavigate();
    
    const toast = useToastMessageQueue();
    
    const { id } = useParams();
    const idNumber = parseInt(id);
    
    const [addressesId, setAddressesId] = useState<number[]>([]);

    const [contactInfosId, setContactInfosId] = useState<number[]>([]);

    const [errors, setErrors] = useState({});

    const [disabled, setDisabled] = useState(false);

    const [disabledNew, setDisabledNew] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);

    const fetchEmployeeDetail = async (idNumber: number) => {
        const res = await api.employeeApi.apiVversionEmployeeIdGet(idNumber, "1").then((response) => {
            if (response.data) {
                setInput({
                    name: response.data?.data?.employee?.name,
                    lastName: response.data?.data?.employee?.lastName,
                    emailAddress: response.data?.data?.employee?.emailAddress
                })
                setAddressesId(response.data?.data?.addressIds);
                setContactInfosId(response.data?.data?.contactInfoIds);
                console.log("Success");
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('employeeDetail.toast.error.fetchEmployeeDetail'), body: error.message });
        });
    };

    useEffect(() => {
        if (idNumber) {
            fetchEmployeeDetail(idNumber);
            setDisabled(true);
        };
        setDisabledNew(true);
    }, [idNumber]);

    const handleInputChange = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const validate = function (input: EmployeeDetailInput) {
        setDisabledNew(false);
        let errors: any = {};
        if (!input.name) {
            errors.name = intl.get('validate.errors.isRequired');
        }
        if (!input.lastName) {
            errors.lastName = intl.get('validate.errors.isRequired');
        }
        if (!input.emailAddress) {
            errors.emailAddress = intl.get('validate.errors.isRequired');
        } else if (!/\S+@\S+\.\S+/.test(input.emailAddress)) {
            errors.emailAddress = intl.get('validate.errors.isInvalid');
        }
        return errors;
    };

    const handleSubmit = async (saveAndEdit: boolean = false) => {
        setLoading(true);
        const errors = validate(input);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (idNumber) {
                let cmd: UpdateEmployeeCommand = {
                    name: input.name,
                    lastName: input.lastName,
                    emailAddress: input.emailAddress,
                    id: idNumber,
                    addressIds: addressesId,
                    contactInfoIds: contactInfosId
                };
                const response = await api.employeeApi.apiVversionEmployeePut("1", cmd, {}).then((response) => {
                    if (saveAndEdit)
                        setDisabled(true);
                    else
                        navigate('/employees');
                    toast.success({ body: intl.get('employeeDetail.toast.success.handleSubmit')});
                    setLoading(false);
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('employeeDetail.toast.error.handleSubmit'), body: error.message });
                    setLoading(false);
                });
            } else {
                let cmd: CreateEmployeeCommand = {
                    name: input.name,
                    lastName: input.lastName,
                    emailAddress: input.emailAddress
                };
                const response = await api.employeeApi.apiVversionEmployeePost("1", cmd, {}).then((response) => {
                    toast.success({ body: intl.get('employeeDetail.toast.success.handleSubmit.add')});
                    setLoading(false);
                    if (saveAndEdit)
                        navigate(`/employee/detail/${response.data.data.id}`);
                    else
                        navigate('/employees');
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('employeeDetail.toast.error.handleSubmit.add'), body: error.message });
                    setLoading(false);
                });
            };
        }
    };

    const handleBackClick = (e) => {
        navigate(-1);
    };

    const handleSaveClick = () => {
        handleSubmit(true);
    };

    const handleSaveCloseClick = () => {
        handleSubmit(false);
    };

    const handleEditMode = (e) => {
        e.preventDefault();
        console.log('handleEditMode', input, disabled);
        if (idNumber) {
            fetchEmployeeDetail(idNumber);
        }
        setErrors({});
        setDisabled(!disabled);
    };

    return (
        <div className='container'>
            <div className='card mt-4'>
                <div className="card-header">
                    {
                        idNumber ? 
                        <>
                            <h2 className="title mb-0">{intl.get('employeeDetail.header')}</h2>
                        </> :
                        <h2 className="title mb-0">{intl.get('newEmployee.header')}</h2>
                    }
                </div>
                <div className='card-body'>
                    <EmployeeForm errors={errors} input={input} handleInputChange={handleInputChange} disabled={disabled}/>
                    <RequiredField/>
                    {
                        !!idNumber &&
                        <>
                            <div className='card my-3'>
                                <AddressCompact 
                                    addressesId={addressesId} 
                                    setAddressesId={setAddressesId} 
                                    disabled={disabled} 
                                    editScopes={[SCOPES['employees.edit']]} 
                                    deleteScopes={[SCOPES['employees.edit']]} 
                                />
                            </div>
                            <div className='card'>
                                <ContactInfoCompact 
                                    contactInfosId={contactInfosId} 
                                    setContactInfosId={setContactInfosId} 
                                    disabled={disabled} 
                                    editScopes={[SCOPES['employees.edit']]} 
                                    deleteScopes={[SCOPES['employees.edit']]}
                                />
                            </div>
                            <div className='card'>
                                <CurrentAssignations idEmployee={idNumber} />
                            </div>
                        </>
                    }
                    <div className='d-flex justify-content-end my-3'>
                        {
                            !idNumber && !disabled ?
                            <>
                                {
                                    loading === true ?
                                    <Spinner small={true} /> :
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                            <HiArrowLeft/>
                                        </button>
                                        <PermissionsGate viewScopes={[SCOPES['employees.edit']]} editScopes={[SCOPES['employees.edit']]} viewRoles={[]} editRoles={[]} >
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabledNew, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabledNew} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                        </PermissionsGate>
                                    </>
                                }
                            </> : null
                        }
                        {
                            idNumber && disabled ?
                            <>
                                <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                    <HiArrowLeft/>
                                </button>
                                <button type='button' className='btn btn-primary me-2' id='edit' onClick={handleEditMode}>   
                                    <HiPencilAlt className='mb-1' /> {intl.get('edit.button')}
                                </button>
                            </> : null
                        }
                        {
                            idNumber && !disabled ?
                            <>
                                {
                                    loading === true ?
                                    <Spinner small={true} /> :
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='edit' onClick={handleEditMode}>   
                                            {intl.get('cancel.button')}
                                        </button>
                                        <PermissionsGate viewScopes={[SCOPES['employees.edit']]} editScopes={[SCOPES['employees.edit']]} viewRoles={[]} editRoles={[]} >
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabled, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabled} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                        </PermissionsGate>
                                    </>
                                }
                            </> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeDetail;

