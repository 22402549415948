import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Handle,
  useReactFlow,
  Position,
  reconnectEdge,
  MarkerType,
  Node,
  Edge,
  Connection,
  Edge as ReactFlowEdge,
  EdgeTypes,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import intl from 'react-intl-universal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GenerateJSON from './GenerateJSON';
import { nanoid } from 'nanoid';
import EditableNode from './EditableNode';
import AddStateModal from './AddStateModal';
import Dagre from '@dagrejs/dagre';
import FloatingEdge from './FloatingEdge';
import FloatingConnectionLine from './FloatingConnectionLine';
import { createNodesAndEdges, getIdByInternalId, getInternalIdById } from './utils';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import Spinner from 'components/Spinner';
import { Row } from 'reactstrap';
import { useParams } from 'react-router';
import ScopeSelect from 'components/Roles/ScopeSelect';
import { WorkflowNodeData } from './WorkflowNodeData';

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];


const defaultViewport = { x: 10, y: 15, zoom: 5 };

const WorkflowEditor: React.FC = () => {
  const { id } = useParams();
  const idNumber = parseInt(id);
  const api: Api = useApi();
  const toast = useToastMessageQueue();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  
  const [loading, setLoading] = useState(true);
  
  const [input, setInput] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [jsonInput, setJsonInput] = useState<string>('');
  const [initialStateId, setInitialStateId] = useState<string | null>(null);
  const edgeReconnectSuccessful = useRef<boolean>(true);

  const onLayout = useCallback(
    (direction: 'TB' | 'LR') => {
      const layouted = getLayoutedElements(nodes, edges, { direction });
      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);
    },
    [nodes, edges]
  );

  useEffect(() => {
    if (idNumber) {
      fetchWorkflowDetail(idNumber);
      setLoading(false);
      
    };
    
  }, [idNumber]);

  useEffect(() => {
    if(input){
      handleLoadJson();
    };
    //setDisabledNew(true);
  }, [input]);

  const fetchWorkflowDetail = async (idNumber: number) => {
    setLoading(true);
    const response = api.workflowApi.apiVversionWorkflowIdGet("1",idNumber, {}).then((response) => {
      if (response.data) {
        setInput(
          response.data.data
        );
        setLoading(false);
        console.log("Success");
      };
    }).catch((error) => {
      if (error.response) {
        console.log("Data :", error.response.data);
        console.log("Status :" + error.response.status);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      toast.error({ header: intl.get('workflowDetail.toast.error.fetchWorkflowDetail'), body: error.message });
      setLoading(false);
    });
  };

  

  const onConnect = useCallback(
    (params: Connection | Edge) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: 'floating',
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: '#FF0072',
            },
          },
          eds
        )
      ),
    [setEdges]
  );

  const getLayoutedElements = (nodes: Node[], edges: Edge[], options: { direction: string }) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: options.direction });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) =>
      g.setNode(node.id, {
        ...node,
        width: node.measured?.width ?? 0,
        height: node.measured?.height ?? 0,
      })
    );

    Dagre.layout(g);

    return {
      nodes: nodes.map((node) => {
        const position = g.node(node.id);
        const x = position.x - (node.measured?.width ?? 0) / 2;
        const y = position.y - (node.measured?.height ?? 0) / 2;

        return { ...node, position: { x, y } };
      }),
      edges,
    };
  };

  const onEdgesUpdate = useCallback(
    (oldEdge: ReactFlowEdge, newConnection: Connection) => {
      setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    },
    [setEdges]
  );

  const onElementsRemove = useCallback(
    (elementsToRemove: (Node | Edge)[]) => {
      setNodes((nds) =>
        nds.filter((node) => !elementsToRemove.find((el) => el.id === node.id))
      );
      setEdges((eds) =>
        eds.filter((edge) => !elementsToRemove.find((el) => el.id === edge.id))
      );
    },
    [setNodes, setEdges]
  );

  const addState = (stateName: string) => {
    if (stateName) {
      const newState= {
        id: nanoid(),
        type: 'editableNode',
        data: { label: stateName },
        position: { x: Math.random() * 800, y: Math.random() * 400 },
        
      };
      setNodes((nds) => [...nds, newState]);
    }
  };

  const updateNodeLabel = useCallback(
    (id: string, newLabel: string) => {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, label: newLabel } } : node
        )
      );
    },
    [setNodes]
  );

  const updateNodeData = useCallback(
    (id: string, data: WorkflowNodeData) => {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, data } } : node
        )
      );
    },
    [setNodes]
  );

  const updateEdgeLabel = (id: string, newLabel: string) => {
    setEdges((eds) =>
      eds.map((edge) =>
        edge.id === id ? { ...edge, data: { ...edge.data, label: newLabel } } : edge
      )
    );
  };

  const getNodeNameById = (id: string) => {
    const node = nodes.find((n) => n.id === id);
    return node ? node.data.label : '';
  };



  const handleJsonInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setJsonInput(event.target.value);
  };

  const handleLoadJson = () => {
    try {
      const  data  = input;
      data.states.forEach((state: any) => {
        state.extraAttributes = JSON.parse(state.extraAttributes??"{}");
      });

      const loadedNodes = data.states.map((state: any) => ({
        id: nanoid(),
        type: 'editableNode',
        data: { ...state.extraAttributes, internalId: `${state.id}`, label: state.name, editScopes: state.editScopes?.split(','), viewScopes: state.viewScopes?.split(','), deleteScopes: state.deleteScopes?.split(','), initial: state.initial, final: state.complete },
        position: {  x:  state.extraAttributes?.position?.x|| Math.random() * window.innerWidth / 2, y:state.extraAttributes?.position?.y|| Math.random() * window.innerHeight / 2 },
        
      }));
      const loadedEdges = data.transitions.map((transition: any) => ({
        id: nanoid(),

        source: getIdByInternalId(loadedNodes, `${transition.idWorkflowStateFrom}`),
        target: getIdByInternalId(loadedNodes, `${transition.idWorkflowStateTo}`),
        data: { ...transition.extraAttributes, label: transition.actionName, internalId: `${transition.id}`, scopes: transition.scopes },
        type: 'floating',
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 30,
          height: 30,
          color: '#black',
        },
      }));
      setNodes(loadedNodes);
      setEdges(loadedEdges);
    } catch (error) {
      alert('Invalid JSON data');
    }
  };

  /*const handleInitialStateChange = (id: string) => {
    setInitialStateId(id === initialStateId ? null : id);
  };*/

  const removeState = (id: string) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
    if (initialStateId === id) setInitialStateId(null);
  };

  const removeEdge = (id: string) => {
    setEdges((eds) => eds.filter((edge) => edge.id !== id));
  };

  const nodeTypes = useMemo(
    () => ({
      editableNode: (props: any) => (
        <div style={{ position: 'relative' }}>
          <Handle type="target" position={Position.Left} />
          <EditableNode
            {...props}
            onChange={updateNodeData}
            isInitial={initialStateId === props.id}

          //onInitialChange={handleInitialStateChange}
          />
          <Handle type="source" position={Position.Right} />
        </div>
      ),
    }),
    [updateNodeData, initialStateId]
  );

  const edgeTypes: EdgeTypes = useMemo(
    () => ({
      floating: FloatingEdge as React.FC<any>,
    }),
    []
  );

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback(
    (oldEdge: ReactFlowEdge, newConnection: Connection) => {
      edgeReconnectSuccessful.current = true;
      setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    },
    [setEdges]
  );

  const onReconnectEnd = useCallback(
    (event: MouseEvent | TouchEvent, edge: ReactFlowEdge) => {
      if (!edgeReconnectSuccessful.current) {
        setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      }
      edgeReconnectSuccessful.current = true;
    },
    []
  );

  return (
    <div className='container'>
      <Row className='justify-content-center'>
        <div className={`${idNumber ? 'card mt-12' : 'card mt-12 '}`}>
          <div className="card-header">
            {
              idNumber ?
                <>
                  <h2 className="title mb-0">{intl.get('workflowEditor.header')}</h2>
                </> :
                <h2 className="title mb-0">{intl.get('newWorkflow.header')}</h2>
            }
          </div>
          {
            loading ?
              <Spinner /> :
              <div className='card-body'>
                <DndProvider backend={HTML5Backend}>
                  <div style={{ height: '50vh' }}>
                    <ReactFlowProvider>
                      <button onClick={() => setIsModalOpen(true)}>Add State</button>
                     {/*} <div style={{ marginTop: '10px' }}>
                        <textarea
                          value={jsonInput}
                          onChange={handleJsonInputChange}
                          placeholder="Enter JSON data here"
                          rows={5}
                          style={{ width: '100%' }}
                        />
                        <button onClick={() => onLayout('TB')}>Vertical Layout</button>
                        <button onClick={() => onLayout('LR')}>Horizontal Layout</button>
                        <button onClick={handleLoadJson}>Load JSON</button>
                      </div>*/}
                      <div className="floatingedges">
                        <ReactFlow
                          nodes={nodes}
                          edges={edges}
                          onNodesChange={onNodesChange}
                          onEdgesChange={onEdgesChange}
                          onConnect={onConnect}

                          //onEdgeUpdate={onEdgesUpdate}
                          // onElementsRemove={onElementsRemove}
                          nodeTypes={nodeTypes}
                          edgeTypes={edgeTypes}
                          //deleteKeyCode={KeyCode.46} // 'delete' key
                          connectionLineComponent={FloatingConnectionLine}
                          defaultViewport={defaultViewport}
                          onReconnect={onReconnect}
                          onReconnectStart={onReconnectStart}
                          onReconnectEnd={onReconnectEnd}
                        >
                          <Controls />
                          <Background />
                        </ReactFlow>
                      </div>
                      <GenerateJSON nodes={nodes} edges={edges} />
                    </ReactFlowProvider>
                    {/*<div style={{ padding: '10px' }}>
                      <h2>Workflow States</h2>
                      <table>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>State Name</th>
                            <th>Initial</th>
                            <th>Actions</th>
                            <th>JSON</th>
                          </tr>
                        </thead>
                        <tbody>
                          {nodes.map((node) => (
                            <tr key={node.id}>
                              <td>{node.id}</td>
                              <td>
                                <input
                                  type="text"
                                  value={node.data?.label?.toString() || ''}

                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={initialStateId === node.id}
                                // onChange={() => handleInitialStateChange(node.id)}
                                />
                              </td>
                              <td>
                                <button onClick={() => removeState(node.id)}>Remove</button>
                              </td>
                              <td>{JSON.stringify(node)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <h2>Workflow Transitions</h2>
                      <table>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Action Name</th>
                            <th>Actions</th>
                            <th>JSON</th>
                          </tr>
                        </thead>
                        <tbody>
                          {edges.map((edge) => (
                            <tr key={edge.id}>
                              <td>{edge.id}</td>
                              <td>{getNodeNameById(edge.source)}</td>
                              <td>{getNodeNameById(edge.target)}</td>
                              <td>
                                <input
                                  type="text"
                                  value={edge.data?.label?.toString() || ''}
                                  onChange={(e) => updateEdgeLabel(edge.id, e.target.value)}
                                />
                              </td>
                              <td>
                                <button onClick={() => removeEdge(edge.id)}>Remove</button>
                              </td>
                              <td>{JSON.stringify(edge)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    */}
                    <AddStateModal
                      isOpen={isModalOpen}
                      onRequestClose={() => setIsModalOpen(false)}
                      onAddState={addState}
                    />
                  </div>
                </DndProvider>
              </div>
          }
        </div>
      </Row>
    </div>);



};

const FlowWithProvider: React.FC = (props) => {
  return (
    <ReactFlowProvider>
      <WorkflowEditor {...props} />
    </ReactFlowProvider>
  );
};

export default FlowWithProvider;
