import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { Employee } from 'axiosApi/models';
import { useToastMessageQueue } from '../../components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import TimeOffRequestForm from './TimeOffRequestForm';
import RequestedTimeOff from './RequestedTimeOff';
import '../../scss/Time Off/TimeOffRequest.scss';
import { addDays } from "date-fns";
import { DateRange } from 'react-day-picker';
import { getUserEntity } from 'common/UserEntityProvider';
import { UpdateTimeOffCommand } from 'axiosApi/models/update-time-off-command';
import { WorkflowProvider } from 'components/Workflow/WorkflowContext';

export type TimeOffInput = {
    id: number | null,
    range: DateRange | undefined,
    title: string | null,
    idEmployee: number,
    employeeName: string | null,
    employeeLastName: string | null,
    idWorkflowState: number | null,
    workflowStateName: string | null,
    changeLog: string | null,
    idTimeOffRequestType: number | null,
    timeOffRequestTypeName: string | null,
	attachments?: any,
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: any
};

const TimeOffRequest = ({ HRView }) => {

    const initialRange: DateRange = {
        from: new Date(),
        to: addDays(new Date(), 4)
    };
    const [comments, setComments] = useState("");

    const { id } = useParams();
    const idNumber = id && parseInt(id);


    const userDetails = getUserEntity();

    const [input, setInput] = useState<TimeOffInput | null>({
        id: null,
        range: initialRange,
        title: "",
        idEmployee: userDetails.entityId,
        employeeName: "",
        employeeLastName: "",
        idWorkflowState: null,
        workflowStateName: "",
        changeLog: "",
        idTimeOffRequestType: null,
        timeOffRequestTypeName: "",
        attachments: []
	});

    const [searchResult, setSearchResult] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});

    const [requestType, setRequestType] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const [employees, setEmployees] = useState<Employee[]>(null);

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const navigate = useNavigate();

    const fetchRequestType = async () => {
        const response = await api.timeOffRequestTypeApi.apiVversionTimeOffRequestTypeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setRequestType({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            } else {
                setRequestType({
                    totalRecords: null,
                    queryResult: null
                });
            };
            console.log("Success");
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequestType'), body: error.message });
        });
    };

    const fetchEmployees = async () => {
        const response = await api.employeeApi.apiVversionEmployeeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setEmployees(response.data.data.queryResult);
            };
            console.log("Success");
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('employee.toast.error.fetchEmployees'), body: error.message });
        });
    };

    useEffect(() => {
        fetchRequestType();
        if (HRView) {
            fetchEmployees();
        }
        if (idNumber) {
            fetchTimeOffApprove(idNumber);
        }
     
	}, []);

    const fetchTimeOffApprove = async (idNumber: number) => {
        setLoading(true);
        const response = await api.timeOffApi.apiVversionTimeOffIdGet(idNumber, "1", {}).then((response) => {
            if (response.data) {
                setInput({
                    ...input,
                    changeLog: response.data?.data?.changeLog,
                    employeeName: response.data?.data?.employeeName,
                    employeeLastName: response.data?.data?.employeeLastName,
                    range: { from: response.data?.data?.from, to: response.data?.data?.to },
                    id: response.data?.data?.id,
                    idEmployee: response.data?.data?.idEmployee,
                    idTimeOffRequestType: response.data?.data?.idTimeOffRequestType,
                    idWorkflowState: response.data?.data?.idWorkflowState,
                    timeOffRequestTypeName: response.data?.data?.timeOffRequestTypeName,
                    title: response.data?.data?.title,
                    workflowStateName: response.data?.data?.workflowStateName,
                });
                console.log("Success");
                setLoading(false);
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('timeOffApprove.toast.error.fetchTimeOffApprove'), body: error.message });
            setLoading(false);
        });
    };

    
    const validate = (input) => {
        let errors: any = {};
        if (!input.range) {
            errors.range = intl.get('validate.errors.isRequired');
        }
        if (!input.idTimeOffRequestType) {
            errors.idTimeOffRequestType = intl.get('validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };
    
    const handleRequestChange = (e) => {
        if (e !== null) {
            setInput({...input, idTimeOffRequestType: e.id});
            setErrors(validate({
                ...input,
                idTimeOffRequestType: e.id
            }));
        } else {
            setInput({...input, idTimeOffRequestType: null});
        };
    };

    const handleDaySelect = (date: DateRange | undefined) => {
        if (date) {
            setInput({...input, range: date});
            setErrors(validate({
                ...input,
                range: date
            }));
        };
    };

    const handleEmployeeChange = (e) => {
        const inputVal = {
            ...input,
            idEmployee: e.id
        };
        setInput({ ...inputVal });
    };

    const formatDateISO = (dateString) => {
        const day = String(dateString.getDate()).padStart(2, '0');
        const month = String(dateString.getMonth() + 1).padStart(2, '0');
        const year = dateString.getFullYear();
        const dateISO = new Date(`${year}-${month}-${day}T00:00:00`);
        return dateISO;
    };

    const handleSave = async (id, comments) => {
        setLoading(true);
        
        let cmdJSON: UpdateTimeOffCommand = {
            id: input.id,
            from: input.range.from,
            to: input.range.to,
            title: input.title,
            idEmployee: input.idEmployee,
            idWorkflowState: id,
            changeLog: input.changeLog,
            idTimeOffRequestType: input.idTimeOffRequestType,
            comments: comments
        };
        let cmd = JSON.stringify(cmdJSON);
        const response = await api.timeOffApi.apiVversionTimeOffPutForm("1", cmd, input.attachments, [], {}).then((response) => {
            if (response.data) {
                toast.success({ body: intl.get('calendarEvent.toast.success.handleSave')});
                navigate(-1);
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('calendarEvent.toast.error.handleSave'), body: error.message });
        });
        setLoading(false);
    };

    return (
        <div className="container" style={{width: "900px"}}>
            <WorkflowProvider  workflowId={3}>
            <div className='card mt-4'>
                <div className="card-header">
                    <h2 className="title">{intl.get('timeOff.request.header')}</h2>
                </div>
                <div className='card-body'>
                    <TimeOffRequestForm
                        requestType={requestType?.queryResult}
                        input={input}
                        setInput={setInput}
                        errors={errors}
                        loading={loading}
                        handleRequestChange={handleRequestChange}
                        handleDaySelect={handleDaySelect}
                        handleSave={handleSave}
                        employees={employees}
                        handleEmployeeChange={handleEmployeeChange}
                        HRView={HRView}
                        setComments={setComments}
                    />
                    <div className='mt-20'>
                        <h4 className="timeOffTitle">{intl.get('timeOff.requested.header')}</h4>
                        {
                            loading === true ?
                            <Spinner /> :
                            <RequestedTimeOff timeOffData={searchResult}/>
                        }
                    </div>
                </div>
            </div>
            </WorkflowProvider>
        </div>
    )
}

export default TimeOffRequest;