import React from 'react';

const CurrentAssignationsRow = ({ projectName, hourlyRate, monthlyRate, roleName, dueDate, startDate, allocation, allocationType }) => {

    return (
        <>
            {
                projectName ?
                <tr>
                    <td>{projectName}</td>
                    <td className='text-center'>{hourlyRate}</td>
                    <td className='text-center'>{monthlyRate}</td>
                    <td className='text-center'>{allocation}{allocationType==1?' %': ' hs.'}</td>
                    <td>{roleName}</td>
                    <td className='text-center'>{startDate} - {dueDate}</td>
                </tr> :
                null
            }
        </>
    )
}

export default CurrentAssignationsRow;