import React, { useState, useEffect } from "react";
import JobList from "./JobList";
import JobTypeSelect from "./JobTypeSelect";
import Spinner from '../Spinner';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { CreateJobCommand, UpdateJobCommand, JobType, Job } from "axiosApi/models";
import { useToastMessageQueue } from "components/ToastMessages/ToastMessageProvider";
import { Button, Input, Label } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import TaskJobModal from "./TaskJobModal";
import { PermissionsGate } from "components/PermissionsGate";
import { SCOPES } from "common/permissions";
import { HiOutlineSave } from "react-icons/hi";
import RequiredField from '../RequiredField';
import intl from 'react-intl-universal';
import JobProjectPagination from "./JobProjectPagination";
import ItemsSelect from '../ItemsSelect';

export type JobProjectInput = {
    description: string,
    billable: boolean,
    idProject: number,
    idJobType: number | null
};

type JobProjectProps = {
    idProject: number,
    isJiraReady: boolean,
    clientName?: string,
    projectName?: string,
    disabled: boolean,
    errors: any,
    setErrors: any
}

const JobProject = ({ idProject, isJiraReady, clientName, projectName, disabled, errors, setErrors }: JobProjectProps) => {

    const DefaultJobProjectInput = {
        description: '',
        billable: false,
        idProject: idProject,
        idJobType: null,
        currentPage: 1
    };

    const [input, setInput] = useState<CreateJobCommand | UpdateJobCommand | null>(DefaultJobProjectInput);

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [jobsByProject, setJobsByProject] = useState<Job[]>(null);

    const [jobTypes, setJobTypes] = useState<JobType[]>(null);

    const [taskJobEdit, setTaskJobEdit] = useState<number | null>(null);

    const [taskJobModalOpen, setTaskJobModalOpen] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [pageCount, setpageCount] = useState<number>(0);

    const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageJobProyect'), 10) || 10;
	});

    useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageJobProyect'), 10) || 10);
	}, []);

    const fetchJobTypes = async () => {
        api.jobTypeApi.apiVversionJobTypeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setJobTypes(response.data.data.queryResult);
            };
        }).catch((error) => {
            toast.error({ header: intl.get('jobProject.toast.error.fetchJobTypes'), body: error.message });
        });
    };

    const fetchJobsByProject = async (idProject: number) => {
        setLoading(true);
        await api.jobApi.apiVversionJobGetByProjectIdIdGet(idProject, "1", {}).then((response) => {
            if (response.data.data) {
                setJobsByProject(response.data.data.queryResult);
            };
            setLoading(false);
        }).catch((error) => {
            toast.error({ header: intl.get('jobProject.toast.error.fetchJobsByProject'), body: error.message });
            setLoading(false);
        });
    };

    useEffect(() => {
        if (jobTypes == null) {
            fetchJobTypes();
        };
    });

    useEffect(() => {
        if (idProject) {
            fetchJobsByProject(idProject);
        };
    }, [idProject, limit]);

    const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    const jobsByProjectPaginated = jobsByProject?.slice((input.currentPage - 1) * limit, input.currentPage * limit);

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const inputVal = {
            ...input,
            [e.target.name]: e.target.value
        };
        setInput({ ...inputVal });
        validate({ ...inputVal });
    };

    const jobHasTimeLogs = async (idJob: number) => {
        return await api.timesheetApi.apiVversionTimesheetGetByJobIdJobGet(idJob, "1", 0, 0, {}).then(async response => {
            return response.data.data.totalRecords > 0;
        }
        ).catch((error) => {
            toast.error({ header: intl.get('jobProject.toast.error.jobHasTimeLogs'), body: error.message });
            return true;
        });
    };

    const handleBillableChange = (e: { target: { name: any; checked: any; }; }) => {
        const checked = e.target.checked;
        if (isEditMode()) {
            jobHasTimeLogs((input as UpdateJobCommand).id).then(value => {
                if (value) {

                    confirmAlert({
                        title: intl.get('delete.modal.title'),
                        message: intl.get('confirm.modal.jobProject.handleBillableChange.message'),
                        buttons: [
                            {
                                label: 'Cancel',
                                onClick: () => { }
                            },
                            {
                                label: 'Accept',
                                onClick: () => {
                                    setInput({
                                        ...input,
                                        billable: checked
                                    });

                                },
                            }
                        ]
                    });
                } else {
                    setInput({
                        ...input,
                        billable: checked
                    });
                }
            });
        } else {
            setInput({
                ...input,
                billable: checked
            });
        }
    };

    const handleJobTypeChange = (idJobType) => {
        const inputVal = {
            ...input,
            idJobType: idJobType.id
        };
        setInput({ ...inputVal });
        validate({ ...inputVal });
    };

    const validate = (inputVal) => {
        let errors = {};
        if (!inputVal.description) {
            errors['description'] = ' is required';
        }
        if (!inputVal.idJobType) {
            errors['idJobType'] = ' is required';
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : null;
    };

    const handleAddJobProject = async (e) => {
        e.preventDefault();
        const errors = validate(input);
        if (errors == null) {
            await api.jobApi.apiVversionJobPost("1", input, {}).then((response) => {
                toast.success({ body: intl.get('employeeProject.toast.success.handleAddJobProject')})
                setInput(DefaultJobProjectInput);
                if (jobsByProject)
                    setJobsByProject((jobs) => [...jobs, response.data.data]);
                else
                    setJobsByProject([response.data.data]);
                response.data.data
            }).catch((error) => {
                toast.error({ header: intl.get('employeeProject.toast.error.handleAddJobProject'), body: error.message });
            });
        };
    };

    const handleSaveJobProject = async (e) => {
        e.preventDefault();
        const errors = validate(input);
        if (errors == null) {
            await api.jobApi.apiVversionJobPut("1", input, {}).then((response) => {
                toast.success({ body: intl.get('employeeProject.toast.success.handleSaveJobProject')})
                setInput(DefaultJobProjectInput);
                setJobsByProject((jobs) => [...jobs.map(j => { if (j.id != (input as UpdateJobCommand).id) return j; else return response.data.data; })]);
            }).catch((error) => {
                toast.error({ header: intl.get('employeeProject.toast.error.handleSaveJobProject'), body: error.message });
            });
        };
    };

    const handleJobEdit = (idJob: number) => {
        if (!isEditMode()) {
            const editingJob = jobsByProject.find((job: Job) => job.id == idJob);
            if (editingJob != null)
                setInput({ ...editingJob, id: idJob, currentPage: input.currentPage });
            else
                toast.error({ body: intl.get('employeeProject.toast.error.handleJobEdit')});
        } else {
            setInput(DefaultJobProjectInput);
        };
    };
    
    const handleJobDelete = (idJob: number) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.jobProject.message'),
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => { }
                },
                {
                    label: 'Delete',
                    onClick: () => {
                        api.jobApi.apiVversionJobIdDelete(idJob, "1", {}).then((response) => {
                            if (response.status == 200) {
                                toast.success({ body: intl.get('employeeProject.toast.success.handleJobDelete')})
                                setJobsByProject((jobs) => [...jobs.filter(j => j.id != idJob)]);
                            }
                            else {
                                toast.error({ header: intl.get('employeeProject.toast.error.handleJobDelete')});
                            }
                        }).catch((error) => {
                            toast.error({ header: intl.get('employeeProject.toast.error.handleJobDelete'), body: error.message });
                        });
                    },
                }
            ]
        });
    };

    const handleTaskJob = (idJob: number) => {
        setTaskJobEdit(idJob);
        setTaskJobModalOpen(true);
    };

    const isEditMode = () => {
        return Object.keys(input).includes('id');
    };

    const handleCancelEdit = () => {
        setInput(DefaultJobProjectInput);
    };

    const isJobEditMode = (idJob: number) => {
        
        return isEditMode() && (input as UpdateJobCommand).id === idJob;
    };

    const getJobName = (idJob: number): string => {
        return jobsByProject.find(j => j.id == idJob).description;
    };

    const closeModal = () => {
        setTaskJobModalOpen(false);
        setTaskJobEdit(null);
    };

    return (
        <>
            <div className="card-header">
                <h2 className="title" id="job">{intl.get('jobProject.header')}</h2>
            </div>
            <div className="card-body">
                <PermissionsGate viewScopes={[SCOPES['projects.job.add'], SCOPES['projects.job.edit']]} editScopes={[SCOPES['projects.job.add'], SCOPES['projects.job.edit']]} viewRoles={[]} editRoles={[]} >
                    <div className="row">
                        <div className="col-md-4">
                            <Label check for="description" className={`mx-2 ${errors.description ? 'text-danger' : ''}`}>
                                {intl.get('jobProject.body.description')} {errors.description && (<span className='text-danger'>{errors.description}</span>)}
                            </Label>
                            <Input
                                type="text"
                                className="form-control my-2"
                                id="description"
                                name="description"
                                maxlength="45"
                                placeholder={intl.get('jobProject.body.placeholder.description')}
                                onChange={handleInputChange}
                                value={disabled ? '' : input.description}
                                disabled={disabled}
                            ></Input>
                        </div>
                        <div className="col-md-2" style={{ textAlign: 'center' }}>
                            <Label check for="billable" className="mx-2">
                                {intl.get('jobProject.body.billable')}
                            </Label>
                            <Input
                                type="checkbox"
                                className="form-control my-3 mx-auto"
                                id="billable"
                                name="billable"
                                onChange={handleBillableChange}
                                checked={disabled ? false : input.billable}
                                disabled={disabled}
                            ></Input>
                        </div>
                        <div className="col-md-3">
                            <Label check for="description" className={`mx-2 ${errors.idJobType ? 'text-danger' : ''}`}>
                                {intl.get('jobProject.body.jobType')} {errors.idJobType && (<span className='text-danger'>{errors.idJobType}</span>)}
                            </Label>
                            <JobTypeSelect
                                handleJobTypeChange={handleJobTypeChange}
                                selectedJobType={input.idJobType}
                                jobTypes={jobTypes}
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-md-2">
                            {
                                !isEditMode() &&
                                <PermissionsGate viewScopes={[SCOPES['projects.job.add']]} editScopes={[SCOPES['projects.job.add']]} viewRoles={[]} editRoles={[]} >
                                    <div className="d-flex justify-content-start align-items-center h-100">
                                        <Button outline type="submit" className={disabled ? "btn btn-primary mx-0 mb-md-2 ms-md-2 mt-auto flex-fill flex-md-grow-0 disabled" : "btn btn-primary mx-0 mb-md-2 ms-md-2 mt-auto flex-fill flex-md-grow-0"} onClick={handleAddJobProject}>{intl.get('projectDetail.add.button')}</Button>
                                    </div>
                                </PermissionsGate>
                            }
                            {
                                isEditMode() &&
                                <PermissionsGate viewScopes={[SCOPES['projects.job.edit']]} editScopes={[SCOPES['projects.job.edit']]} viewRoles={[]} editRoles={[]} >
                                    <div className="d-flex justify-content-start align-items-cente h-100">
                                        <Button outline className="btn btn-outline-dark mx-0 mb-md-2 ms-md-2 mt-auto flex-fill flex-md-grow-0" onClick={handleCancelEdit}>{intl.get('projectDetail.cancel.button')}</Button>
                                        <Button outline type="submit" className="btn btn-primary ms-2 mx-0 mb-md-2 ms-md-2  mt-auto  flex-fill flex-md-grow-0" onClick={handleSaveJobProject}><HiOutlineSave className='mb-1'/> {intl.get('projectDetail.save.button')}</Button>
                                    </div>
                                </PermissionsGate>
                            }
                        </div>
                        <RequiredField/>
                    </div>
                </PermissionsGate>
            </div>
            <div className='row m-0'>
                {
                    loading === true ?
                    <Spinner small={true} /> :
                    <>
                        <JobList jobTypes={jobTypes} jobsByProjectPaginated={jobsByProjectPaginated} isJiraReady={isJiraReady} handleJobEdit={handleJobEdit} handleTaskJob={handleTaskJob} handleJobDelete={handleJobDelete} isJobEditMode={isJobEditMode} disabled={disabled} input={input}/>
                        <div className="pagination row w-100">
                            <div className="col-10 col-xl-11 d-flex justify-content-center">
                                <JobProjectPagination handlePageClick={handlePageClick} limit={limit} jobsByProject={jobsByProject} nextPage={nextPage} prevPage={prevPage} input={input} pageCount={pageCount} setpageCount={setpageCount} initialPage={initialPage} finalPage={finalPage}/>
                            </div>
                            <div className="col-2 col-xl-1 d-flex justify-content-end">
                                <ItemsSelect
                                    input={input}
                                    setInput={setInput}
                                    limit={limit} 
                                    setLimit={setLimit}
                                    storageKey="itemsPerPageJobProyect"
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
            {taskJobModalOpen && taskJobEdit != null && <TaskJobModal isOpen={taskJobModalOpen} closeModal={closeModal} idProject={idProject} idJob={taskJobEdit} JobName={getJobName(taskJobEdit)} ProjectName={projectName} ClientName={clientName} isJiraReady={isJiraReady} ></TaskJobModal>}
        </>
    );
}

export default JobProject;