import React, { useState, useEffect } from 'react';
import WorkflowNavbar from './WorkflowNavbar';
import WorkflowPagination from './WorkflowPagination';
import WorkflowList from './WorkflowList';
import Spinner from '../Spinner';
import '../../scss/Workflow/Workflow.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import {PermissionsGate } from '../PermissionsGate';
import { SCOPES, ROLES } from "../../common/permissions"; 
import { Workflow } from '../../axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import ItemsSelect from '../ItemsSelect';

export type WorkflowInput = {
	queryTerm?: string | null,
	sortValue?: string | null,
	orderValue?: string | null,
	filterValue?: null,
	currentPage?: number,
	clicked?: boolean
};

export type SearchResult = {
	totalRecords: number | null,
	workflows: Workflow[]
};

const Providers = () => {

	const [input, setInput] = useState<WorkflowInput | null>({
		queryTerm: "",
		sortValue: "Name",
		orderValue: "asc",
		filterValue: null,
		currentPage: 1,
		clicked: false,
	});

	const api: Api = useApi();

	const [searchResult, setSearchResult] = useState<SearchResult | null>({
		totalRecords: null,
		workflows: []
	});

	const [loading, setLoading] = useState<boolean>(false);

	const [pageCount, setpageCount] = useState<number>(0);

	const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageWorkflows'), 10) || 10;
	});

	useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageWorkflows'), 10) || 10);
	}, []);

	const toast = useToastMessageQueue();

	const fetchWorkflows = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
		setLoading(true);
		const response = await api.workflowApi.apiVversionWorkflowAllGet("1", input.currentPage, limit).then((response) => { //input.queryTerm, , input.sortValue, input.orderValue, f, {}
			if(response.data.data === null) {
				setSearchResult({
					totalRecords: null,
					workflows: null
				});
			} else {
				setSearchResult({
					totalRecords: response.data?.data?.totalRecords,
					workflows: response.data?.data?.queryResult
				});
			};
			setLoading(false);
			console.log("Success");
		}).catch((error) => {
			if (error.response) {
				console.log("Data :", error.response.data);
				console.log("Status :" + error.response.status);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log("Error", error.message);
			}
            toast.error({ header: intl.get('workflows.toast.error.fetchWorkflows'), body: error.message });
            setLoading(false);
		});
	};

	useEffect(() => {
        fetchWorkflows();
    }, [input, limit]);

	const onSearch = (queryString) => {
		setInput({ ...input, queryTerm: queryString, currentPage: 1 });
	};

	const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    return (
        <div className="container">
			<div className='card mt-4'>
				<div className="card-header">
					<h2 className="title">{intl.get('workflows.header')}</h2>
				</div>
				<div className='card-body'>
                    <PermissionsGate viewScopes={[SCOPES['workflows.read']]} editScopes={[SCOPES['workflows.edit']]} viewRoles={null} editRoles={null} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
						<div className='row'>
							<div className='' id={`${input.clicked ? 'column-2' : ''}`} >
								<WorkflowNavbar onSearch={onSearch}/>
								{
									loading === true ?
									<Spinner /> :
									<>
										<WorkflowList searchResult={searchResult} input={input} setInput={setInput} />
										<div className="pagination row w-100">
											<div className="col-10 col-xl-11 d-flex justify-content-center">
												<WorkflowPagination 
													handlePageClick={handlePageClick} 
													limit={limit} searchResult={searchResult} 
													nextPage={nextPage} prevPage={prevPage} 
													input={input} pageCount={pageCount} 
													setpageCount={setpageCount} 
													initialPage={initialPage} 
													finalPage={finalPage}
												/>
											</div>
											<div className="col-2 col-xl-1 d-flex justify-content-end">
												<ItemsSelect
													input={input}
													setInput={setInput}
													limit={limit} 
													setLimit={setLimit} 
													storageKey="itemsPerPageProviders"
												/>
											</div>
										</div>
									</>
								}
							</div>
						</div>
                	</PermissionsGate>
				</div>
			</div>
		</div>
    )
}

export default Providers;
