import {ClientApi} from './apis/client-api';
import {ContactInfoApi} from './apis/contact-info-api';
import {AddressApi} from './apis/address-api';
import {EmployeeApi} from './apis/employee-api';
import {JobApi} from './apis/job-api';
import {JobTypeApi} from './apis/job-type-api';
import {TaskApi} from './apis/task-api';
import {EmployeeProjectApi} from './apis/employee-project-api';
import {ProjectApi} from './apis/project-api';
import {TimesheetApi} from './apis/timesheet-api';
import {RoleApi} from './apis/role-api';
import { TimeSheetDetailedReportApi } from './apis/time-sheet-detailed-report-api';
import { LoginApi } from './apis/login-api';
import { BillingDetailedReportApi } from './apis/billing-detailed-report-api';
import { UserApi } from './apis/user-api';
import { Settings } from 'common/settings';
import { SecurityRoleApi } from './apis/security-role-api';
import { ProviderApi } from './apis/provider-api';
import { ScopeApi } from './apis/scope-api';
import { ListApi } from './apis/list-api';
import { TimeOffApi } from './apis/time-off-api';
import { NonWorkingDayApi } from './apis/non-working-day-api';
import { EventApi } from './apis/event-api';
import { TimeOffRequestTypeApi } from './apis/time-off-request-type-api';
import { WorkflowApi } from './apis/workflow-api';
import { ModuleApi } from './apis/module-api';

export default class Api{

    public clientApi: ClientApi;
    public addressApi: AddressApi;
    public contactInfoApi : ContactInfoApi;
    public jobApi: JobApi;
    public jobTypeApi: JobTypeApi;
    public taskApi: TaskApi;
    public employeeApi: EmployeeApi;
    public employeeProjectApi: EmployeeProjectApi;
    public projectApi: ProjectApi;
    public timesheetApi: TimesheetApi;
    public roleApi: RoleApi;
    public timesheetReportsApi: TimeSheetDetailedReportApi;
    public billingReportsApi: BillingDetailedReportApi;
    public loginApi: LoginApi;
    public userApi: UserApi;
    public securityRoleApi: SecurityRoleApi;
    public providerApi: ProviderApi;
    public scopeApi: ScopeApi;
    public timeOffApi: TimeOffApi;
    public nonWorkingDayApi: NonWorkingDayApi;
    public workflowApi: WorkflowApi;
    public moduleApi: ModuleApi;
    public eventApi: EventApi;
    public listApi: ListApi;
    public timeOffRequestTypeApi: TimeOffRequestTypeApi;

    constructor()
    {
        const accessToken = sessionStorage.getItem('accessToken');
        const userEntityId = JSON.parse(sessionStorage.getItem("UserEntity"))?.id ;
        
        const getApiKey = (e:string) =>{ switch (e)
            {
                case "Authorization":
                    return "Bearer " + sessionStorage.getItem('accessToken');
                case "Userentity":
                    return JSON.parse(sessionStorage.getItem("UserEntity"))?.id ;
            } 
        }
        this.clientApi = new ClientApi({basePath: Settings.getInstance().BASE_API_URL_CLIENTS , apiKey: getApiKey});
        this.addressApi = new AddressApi({basePath: Settings.getInstance().BASE_API_URL_ADDRESS, apiKey: getApiKey});
        this.jobApi = new JobApi({basePath: Settings.getInstance().BASE_API_URL_JOBS, apiKey: getApiKey});
        this.jobTypeApi = new JobTypeApi({basePath: Settings.getInstance().BASE_API_URL_JOBS, apiKey: getApiKey});
        this.taskApi = new TaskApi({basePath: Settings.getInstance().BASE_API_URL_JOBS, apiKey: getApiKey});
        this.employeeApi = new EmployeeApi({basePath: Settings.getInstance().BASE_API_URL_EMPLOYEE, apiKey: getApiKey});
        this.contactInfoApi = new ContactInfoApi({basePath: Settings.getInstance().BASE_API_URL_CONTACTINFO , apiKey: getApiKey});
        this.employeeProjectApi = new EmployeeProjectApi({basePath: Settings.getInstance().BASE_API_URL_PROJECT , apiKey: getApiKey});
        this.projectApi = new ProjectApi({basePath: Settings.getInstance().BASE_API_URL_PROJECT , apiKey: getApiKey});
        this.timesheetApi = new TimesheetApi({basePath: Settings.getInstance().BASE_API_URL_TIMESHEET , apiKey: getApiKey});
        this.roleApi = new RoleApi({basePath: Settings.getInstance().BASE_API_URL_PROJECT , apiKey: getApiKey});
        this.timesheetReportsApi = new TimeSheetDetailedReportApi({basePath: Settings.getInstance().BASE_API_URL_REPORTS, apiKey: getApiKey});
        this.billingReportsApi = new BillingDetailedReportApi({basePath: Settings.getInstance().BASE_API_URL_REPORTS , apiKey: getApiKey});
        this.loginApi = new LoginApi({basePath: Settings.getInstance().BASE_API_URL_EMPLOYEE, apiKey: getApiKey});
        this.userApi = new UserApi({basePath: Settings.getInstance().BASE_API_URL_EMPLOYEE, apiKey: getApiKey});
        this.scopeApi = new ScopeApi({basePath: Settings.getInstance().BASE_API_URL_EMPLOYEE, apiKey: getApiKey});
        this.providerApi = new ProviderApi({basePath: Settings.getInstance().BASE_API_URL_EMPLOYEE, apiKey: getApiKey});
        this.securityRoleApi = new SecurityRoleApi({basePath: Settings.getInstance().BASE_API_URL_EMPLOYEE, apiKey: getApiKey});
        this.moduleApi = new ModuleApi({basePath: Settings.getInstance().BASE_API_URL_WORKFLOW, apiKey: getApiKey});
        this.timeOffApi = new TimeOffApi({basePath: Settings.getInstance().BASE_API_URL_TIMEOFF, apiKey: getApiKey});
        this.nonWorkingDayApi = new NonWorkingDayApi({basePath: Settings.getInstance().BASE_API_URL_TIMEOFF, apiKey: getApiKey});
        this.eventApi = new EventApi({basePath: Settings.getInstance().BASE_API_URL_TIMEOFF, apiKey: getApiKey});
        this.listApi = new ListApi({basePath: '', apiKey: getApiKey}); // basePath differs per endoint.
        this.timeOffRequestTypeApi = new TimeOffRequestTypeApi({basePath: Settings.getInstance().BASE_API_URL_TIMEOFF, apiKey: getApiKey});
        this.workflowApi = new WorkflowApi({basePath: Settings.getInstance().BASE_API_URL_WORKFLOW, apiKey: getApiKey});
    }


}