import React, { useState } from 'react';
import { getBezierPath, EdgeLabelRenderer, useInternalNode, Position, EdgeProps } from '@xyflow/react';
import { getEdgeParams } from './utils';
import IconPicker from 'react-icons-picker';
export interface FloatingEdgeProps extends EdgeProps {
  
  markerEnd: any;
  
}

const FloatingEdge: React.FC<FloatingEdgeProps> = ({ id, source, target, markerEnd, style, data }) => {
  const sourceNode = useInternalNode(source);
  const targetNode = useInternalNode(target);
  const offset = 20;
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(data.icon);
  if (!sourceNode || !targetNode) {
    return null;
  }

  const handleDoubleClick = () => {
    setIsEditing(!isEditing);
  };

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);
  const [edgePath] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const labelX = (sx + tx) / 2;
  const labelY = (sy + ty) / 2;
  const labelStyle = {
    fontSize: 12,
    transform: ty > sy ? 'none' : 'rotate(180deg)',
  };

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={style}
      /><EdgeLabelRenderer>
      {!isEditing?
        data?.label && (
        
          <text
            style={{
                position: 'absolute',
              fontSize: 12,
              transform: `translate(-50%, -50%) translate(${labelX}px,${ty > sy ? labelY - offset : labelY + offset}px)`,
              pointerEvents: 'all',
            }}
          >
            
            <textPath href={`#${id}`} startOffset="50%" textAnchor="middle" onDoubleClick={handleDoubleClick}>
              {data.label}
            </textPath>
            
            
          
          </text>
        
      ):<div onDoubleClick={handleDoubleClick}
      style={{
                border: '1px solid #777',
                padding: '10px',
                borderRadius: '5px',
                background: 'white',
                width: isEditing ? '400px' : '150px',
                height: isEditing ? '400px' : '40px',
                minWidth: '150px',
                minHeight: '40px',
                textAlign: isEditing ? 'left' : 'center',
                transition: 'width 1s, height 1s', 
                zIndex: 1000000,
              }}>
      <div className="row">
        <div className='d-inline-flex col-12 col-md-5 flex-column'>
          <label htmlFor="name" className={`form-label}`}/>
          <input type="text" value={data?.label.toString()}/>
          </div>
          <div className='d-inline-flex col-12 col-md-5 flex-column'>
          <label htmlFor="name" className={`form-label}`}/>
          <IconPicker value={selectedIcon} onChange={(v) => setSelectedIcon(v)} />
          </div>
        </div>
   </div>
   }</EdgeLabelRenderer>
    </>
  );
};

export default FloatingEdge;
